<script
  type="text/javascript"
  src="https://api.longdo.com/map/?key=f010fae992008bfab0ad66b0f92c326a"
></script>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "แก้ไขการขอนุมัติเดินทาง",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "แก้ไขการขอนุมัติเดินทาง",
      items: [
        {
          text: "การเงิน",
        },
        {
          text: "ขอนุมัติเดินทาง",
          href: "/travel-expenses",
        },
        {
          text: "แก้ไขการขอนุมัติเดินทาง",
          active: true,
        },
      ],
      brand: "",
      localDataBranch: "",
      localDataBranchId: "",
      locations: [],
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,
      distanceSum: 0,

      // selectMode: "single",ß
      submit: false,
      userBranchId: "",
      branchId: "",
      vehicleId: "",
      toBranchId: "",
      objective: "",
      departureDate: "",
      saleDate: "",
      modelId: "",
      vin: "",
      saleType: "",
      saleCondition: "",
      downPayment: "",
      premium: "",
      fuelPrice: "",
      loading: false,
      companyVehicle: {
        vin: "",
        platePrefixNumber: "",
        plateNumber: "",
        licensePlate: "",
        branchId: "",
        plateProvinceId: "",
        userId: "",
        brandId: "",
        ownerType: "",
      },
      // rowMasterVehicle:"",
      //   payMethCode: "",
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      saleTypeOptions: [
        {
          stId: "N",
          nameTh: "มือใหม่",
        },
        {
          stId: "C",
          nameTh: "รถมือสอง",
        },
      ],
      saleConditionOptions: [
        {
          scId: "C",
          nameTh: "เงินสด",
        },
        {
          scId: "I",
          nameTh: "ผ่อนชำระ",
        },
        {
          scId: "F",
          nameTh: "ไฟแนนซ์",
        },
      ],
      ownerTypeOptions: [
        {
          otId: "C",
          nameTh: "บริษัท",
        },
        {
          otId: "P",
          nameTh: "ส่วนบุคคล",
        },
      ],
      fuelPrices: 0,
      to_latitude: "",
      to_longitude: "",
      from_latitude: "",
      from_longitude: "",

      distance: "",
      userBranch: "",
      masterModel: [],
      masterBrand: [],
      brandId: "",
      rowMasterVehicle: [],
      rowsProvince: [],
      rowsUser: [],
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      submitformVehicle: false,
      distance: "",
    };
  },
  validations: {
    branchId: { required },
    vehicleId: { required },
    toBranchId: { required },
    objective: { required },
    departureDate: { required },
    saleDate: { required },
    vin: { required },
    saleType: { required },
    saleCondition: { required },
    fuelPrice: { required },

    // companyVehicle: {
    //   vin: {},
    //   platePrefixNumber: { required },
    //   plateNumber: { required },
    //   licensePlate: { required },
    //   branchId: { required },
    //   plateProvinceId: { required },
    //   userId: { required },
    //   brandId: { required },
    //   ownerType: { required },
    // },
  },

  computed: {
    fullLicense: function () {
      return `${this.companyVehicle.platePrefixNumber} ${
        this.companyVehicle.plateNumber
      } ${
        this.companyVehicle.plateProvinceId != null &&
        this.companyVehicle.plateProvinceId.nameTh
          ? this.companyVehicle.plateProvinceId.nameTh
          : ""
      }`;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    if (this.$route.params.trvexpId) {
      this.getData();
      this.getLocation();
    }
    this.loadMap();
  },
  created() {
    // this.getMasterVehicle();
    this.getLocalData();
    this.getSelectMasterBrand();
  },
  methods: {
    loadMap() {
      const map = new longdo.Map({
        placeholder: document.getElementById("map"),
        zoom: 6,
      });
      // this.loading = true;

      map.location(longdo.LocationMode.Geolocation);
      setTimeout(() => {
        this.load(map);
      }, 4000);
    },
    // test(map) {
    //   var start = { lon: 103.45773645735798, lat: 17.474507121091747 };
    //   map.Route.add(start);
    //   const end = map.location();
    //   map.Route.add(end);
    //   map.Route.search();
    // },
    plot(data) {
      const map = new longdo.Map({
        placeholder: document.getElementById("map"),
        zoom: 6,
      });
      data.forEach((m) => {
        const start = { lat: m.from_latitude, lon: m.from_longitude };
        const end = { lat: m.to_latitude, lon: m.to_longitude };
        map.Route.add(start);
        map.Route.add(end);
        map.Route.search();
      });
    },
    load(map) {
      var start = { lat: this.from_latitude, lon: this.from_longitude };
      // map.Route.add(start);
      const end = map.location();

      // var markerEnd = new longdo.Marker(end, {
      //   //  title: "test",
      //   icon: {
      //     url: "https://mmmap15.longdo.com/mmroute/images/destLast.png",
      //     offset: { x: 15, y: 30 },
      //     size: { width: 30, height: 40 },
      //   },
      //   //  detail: "test",
      // });
      // console.log(markerEnd);
      // map.Route.add(markerEnd);
      map.Route.mode(longdo.RouteMode.Distance);
      // map.Route.search();

      // markerEnd.move(end, true);
      map.Route.enableContextMenu();
      map.Route.auto(true);
      this.RouteService(end, start);
    },
    async RouteService(end, start) {
      let url = new URL(
        "https://api.longdo.com/RouteService/json/route/guide?"
      );
      url.search = new URLSearchParams({
        key: "f77a3947b63bbf9cef1850dfdf0fabd6",
        flon: start.lon,
        flat: start.lat,
        tlon: end.lon,
        tlat: end.lat,
        type: "d",
        // tlon: end1.lon,
        // tlat: end1.lat,
      });
      const response = await fetch(url)
        .then((response) => {
          return response.json();
        })

        .catch((error) => {
          return error;
        });
      const results = response.data;
      this.from_latitude = start.lat;
      this.from_longitude = start.lon;
      this.to_latitude = end.lat;
      this.to_longitude = end.lon;
      if (results != undefined) {
        const arr = [];
        // let sum = 0;
        results.forEach((element) => {
          // sum += element.distance;
          arr.push(element.distance);
        });
        this.distance = Math.floor(arr / 1000);
      }

      // this.fuelPrices = Math.floor((sum / 1000) * 3);
    },
    SubmitForm() {
      this.submit = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.RouteSubmit();
      }
    },
    RouteSubmit() {
      //  this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/travel-expenses/store-location", {
          trvexpId: atob(this.$route.params.trvexpId),
          from_latitude: this.from_latitude,
          from_longitude: this.from_longitude,
          to_latitude: this.to_latitude,
          to_longitude: this.to_longitude,
          distance: this.distance,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getLocation();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          // this.overlayFlag = false; //skeleton true
        });
    },
    customLabelVm({ licensePlate, vin }) {
      return `${licensePlate != null ? licensePlate : ""} [ vin : ${
        vin != null ? vin : " "
      } ]`;
    },
    customLabel({ nameTh, familyName }) {
      return `${nameTh} ${familyName != null ? familyName : ""} `;
    },
    getLocation() {
      this.overlayFlag = true;
      useNetw
        .get("api/travel-expenses/location", {
          params: {
            trvexpId: atob(this.$route.params.trvexpId),
          },
        })
        .then((response) => {
          if (response.data.location.length != 0) {
            this.locations = response.data.location;
            let sum = 0;
            this.locations.forEach((i) => {
              sum += parseInt(i.distance);
            });
            this.distanceSum = sum;
            this.fuelPrices = Math.floor(this.distanceSum * 3 * 2);
            const loc = this.locations[this.locations.length - 1];
            this.from_latitude = loc.to_latitude;
            this.from_longitude = loc.to_longitude;

            this.loadMap();
            this.plot(this.locations);
          }
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getData() {
      this.overlayFlag = true;
      useNetw
        .get("api/travel-expenses/show", {
          params: {
            trvexpId: atob(this.$route.params.trvexpId),
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.branchId = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };
          this.vehicleId = {
            vehicleId: response.data.data.vehicleId,
            vehicleBrandEn: response.data.data.vehicleModelNameEn,
          };
          this.toBranchId = {
            branchId: response.data.data.toBranchId,
            nameTh: response.data.data.toBranchName,
          };
          this.objective = response.data.data.objective;
          this.departureDate = response.data.data.departureDate;
          this.saleDate = response.data.data.saleDate;
          this.vin = response.data.data.vin;
          this.saleType = {
            stId: response.data.data.saleType,
            nameTh:
              response.data.data.saleType === "N" ? "มือใหม่" : "รถมือสอง",
          };

          if (response.data.data.saleCondition === "I") {
            this.saleCondition = {
              scId: response.data.data.saleCondition,
              nameTh: "ผ่อนชำระ",
            };
          }
          if (response.data.data.saleCondition === "C") {
            this.saleCondition = {
              scId: response.data.data.saleCondition,
              nameTh: "เงินสด",
            };
          }
          if (response.data.data.saleCondition === "F") {
            this.saleCondition = {
              scId: response.data.data.saleCondition,
              nameTh: "ไฟแนนซ์",
            };
          }
          this.downPayment = response.data.data.downPayment;
          this.premium = response.data.data.premium;
          this.fuelPrice = response.data.data.fuelPrice;
          if (this.locations.length == 0) {
            this.from_latitude = response.data.data.latitude;
            this.from_longitude = response.data.data.longitude;
          }

          // this.modelId = {modelId: response.data.data.modelId, nameEn: response.data.data.toBranchName};
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getMasterVehicle();
          this.overlayFlag = false;
        });
    },
    getbranchUser() {
      useNetw
        .get("api/travel-expenses/user", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.companyVehicle.branchId.branchId,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getDataProvince() {
      useNetw
        .get("api/master/address/provinces", {})
        .then((response) => {
          this.rowsProvince = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getSelectMasterBrand: function () {
      useNetw
        .get("/api/master/vehicle/brands", {})
        .then((response) => {
          this.masterBrand = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getSelectMasterModel: function (isReset) {
      if (this.brandId != null) {
        useNetw
          .get("/api/master/vehicle/models", {
            params: {
              branchId: this.userBranchId,
              brandId: this.brandId.brandId,
            },
          })
          .then((response) => {
            this.masterModel = response.data.data;
            if (isReset) {
              this.modelId = null;
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            if (isReset) {
              this.brandId = null;
              this.modelId = null;
            }
          })
          .then(() => {});
      }
    },
    /**
     * Search the table data with search input
     */

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitEx();
      }
    },
    companyVehicleForm() {
      // this.$v.$touch();
      // if (this.$v.companyVehicle.$invalid) {
      // // console.log("false");
      //   this.submitformVehicle = true;
      // } else {
      // do your submit logic here
      this.postVehivle();
      // }
    },
    postVehivle() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/travel-expenses/store-company-vehicle", {
          vin: this.companyVehicle.vin,
          platePrefixNumber: this.companyVehicle.platePrefixNumber,
          plateNumber: this.companyVehicle.plateNumber,
          licensePlate: this.fullLicense,
          branchId:
            this.companyVehicle.branchId != null
              ? this.companyVehicle.branchId.branchId
              : undefined,
          plateProvinceId:
            this.companyVehicle.plateProvinceId != null
              ? this.companyVehicle.plateProvinceId.provinceId
              : undefined,
          userId:
            this.companyVehicle.userId != null
              ? this.companyVehicle.userId.userId
              : undefined,
          brandId:
            this.companyVehicle.brandId != null
              ? this.companyVehicle.brandId.brandId
              : undefined,
          ownerType:
            this.companyVehicle.ownerType != null
              ? this.companyVehicle.ownerType.otId
              : undefined,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs.modal.hide();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    popupModal() {
      this.$refs.modal.show();
      this.getDataProvince();
      // this.getbranchUser();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      // const localBranchIdItem ={};
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.localDataBranchId = localDataBranchId;
      // console.log("localDataBranch=>" , localDataBranch);
    },
    getMasterVehicle() {
      // this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/travel-expenses/company-vehicle", {
          params: {
            branchId: this.branchId.branchId,
            page: this.currentPage,
            perPage: this.perPage,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowMasterVehicle = response.data.data;
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    submitT() {
      if (this.fuelPrice > "0.00") {
        this.submitTravel();
      } else {
        Swal.fire(
          appConfig.swal.title.error,
          "กรุณากรอกค่าน้ำมัน",
          appConfig.swal.type.error
        );
      }
    },
    submitTravel: function () {
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/travel-expenses/submit", {
          trvexpId: atob(this.$route.params.trvexpId),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "travel-expenses",
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    submitEx: function () {
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/travel-expenses/update", {
          trvexpId: atob(this.$route.params.trvexpId),
          vehicleId: this.vehicleId.vehicleId,
          toBranchId: this.toBranchId.branchId,
          objective: this.objective,
          departureDate: this.departureDate,
          saleDate: this.saleDate,
          modelId: this.modelId.modelId,
          vin: this.vin,
          saleType: this.saleType.stId,
          saleCondition: this.saleCondition.scId,
          downPayment: this.downPayment,
          premium: this.premium,
          fuelPrice: this.fuelPrice,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        สาขา :
                        <multiselect
                          :disabled="rows.status != 'DFT'"
                          v-model="branchId"
                          label="nameTh"
                          type="search"
                          :options="localDataBranch"
                          @input="getMasterVehicle"
                          open-direction="bottom"
                          placeholder="สาขา"
                          :class="{
                            'is-invalid': submitform && $v.branchId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.branchId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.branchId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> รถคันที่เลือก :
                        <multiselect
                          :disabled="rows.status != 'DFT'"
                          v-model="vehicleId"
                          label="vehicleBrandEn"
                          type="search"
                          :custom-label="customLabelVm"
                          :options="rowMasterVehicle"
                          open-direction="bottom"
                          placeholder="ชื่อสินค้า"
                          :class="{
                            'is-invalid': submitform && $v.vehicleId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.vehicleId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label for=""></label><br />
                      <button
                        class="btn btn-primary"
                        :disabled="rows.status != 'DFT'"
                        @click="popupModal"
                      >
                        <i class="uil uil-plus"></i>เพิ่มรถ
                      </button>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        ไปที่สาขา :
                        <multiselect
                          :disabled="rows.status != 'DFT'"
                          v-model="toBranchId"
                          label="nameTh"
                          type="search"
                          :options="localDataBranch"
                          open-direction="bottom"
                          placeholder="สาขา"
                          :class="{
                            'is-invalid': submitform && $v.toBranchId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.toBranchId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.toBranchId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-7">
                      <div class="mb-3 position-relative">
                        <code>* </code> จุดประสงค์ :
                        <textarea
                          :disabled="rows.status != 'DFT'"
                          v-model="objective"
                          class="form-control"
                          name="textarea"
                          rows="3"
                          :class="{
                            'is-invalid': submitform && $v.objective.$error,
                          }"
                        ></textarea>
                        <div
                          v-if="submitform && $v.objective.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.objective.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="validation">วันเดินทาง :</label>
                        <date-picker
                          :disabled="rows.status != 'DFT'"
                          v-model="departureDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          :class="{
                            'is-invalid': submitform && $v.departureDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.departureDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.departureDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="validation">วันที่ขาย :</label>
                        <date-picker
                          :disabled="rows.status != 'DFT'"
                          v-model="saleDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          :class="{
                            'is-invalid': submitform && $v.saleDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.saleDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.saleDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6"></div>
                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <label for="validation">ยี่ห้อ:</label>
                        <multiselect
                          :disabled="rows.status != 'DFT'"
                          v-model="brandId"
                          label="nameEn"
                          :options="masterBrand"
                          :show-labels="false"
                          @input="getSelectMasterModel"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label for="validation">รุ่น:</label>
                        <multiselect
                          :disabled="rows.status != 'DFT'"
                          v-model="modelId"
                          label="nameEn"
                          :options="masterModel"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code>* </code>เลขตัวถัง :
                        <input
                          :disabled="rows.status != 'DFT'"
                          class="form-control"
                          v-model="vin"
                          type="text"
                          placeholder="เลขตัวถัง"
                          :class="{
                            'is-invalid': submitform && $v.vin.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.vin.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vin.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code>ประเภทรถ :
                        <multiselect
                          :disabled="rows.status != 'DFT'"
                          v-model="saleType"
                          label="nameTh"
                          type="search"
                          :options="saleTypeOptions"
                          open-direction="bottom"
                          placeholder="ประเภทรถ"
                          :class="{
                            'is-invalid': submitform && $v.saleType.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.saleType.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.saleType.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code>ประเภทการซื้อ :
                        <multiselect
                          :disabled="rows.status != 'DFT'"
                          v-model="saleCondition"
                          label="nameTh"
                          type="search"
                          :options="saleConditionOptions"
                          open-direction="bottom"
                          placeholder="ประเภทการซื้อ"
                          :class="{
                            'is-invalid': submitform && $v.saleCondition.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.saleCondition.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.saleCondition.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        เงินดาวน์ :
                        <input
                          :disabled="rows.status != 'DFT'"
                          class="form-control"
                          v-model="downPayment"
                          type="number"
                          placeholder="เงินดาวน์"
                        />
                      </div>
                    </div>

                    <div class="col-md-7">
                      <div class="mb-3 position-relative">
                        ของแถม :
                        <input
                          :disabled="rows.status != 'DFT'"
                          class="form-control"
                          v-model="premium"
                          type="text"
                          placeholder="ของแถม"
                        />
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code>* </code>ค่าน้ำมัน :
                        <input
                          :disabled="rows.status != 'DFT'"
                          class="form-control"
                          v-model="fuelPrice"
                          type="number"
                          :class="{
                            'is-invalid': submitform && $v.fuelPrice.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.fuelPrice.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.fuelPrice.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-12 col-sm-12 col-md-12">
                        <b-button
                          class="btn float-end m-1"
                          variant="success"
                          :disabled="
                            rows.status === 'SUBMIT' ||
                            rows.status === 'NAPV' ||
                            rows.status === 'BAPV' ||
                            rows.status === 'APV'
                          "
                          @click="tooltipForm"
                        >
                          บันทึก
                        </b-button>

                        <b-button
                          class="btn float-end m-1"
                          variant="danger"
                          @click="submitT"
                          v-if="
                            rows.status !== 'SUBMIT' &&
                            rows.status !== 'NAPV' &&
                            rows.status !== 'BAPV' &&
                            rows.status !== 'APV'
                          "
                        >
                          ส่งขออนุมัติ
                        </b-button>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr class="table-active">
                              <th scope="col">#</th>
                              <th scope="col">จากละติจูด</th>
                              <th scope="col">จากลองจิจูด</th>
                              <th scope="col">ถึงละติจูด</th>
                              <th scope="col">ถึงลองจิจูด</th>
                              <th scope="col">ระยะทาง</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="locations.length == 0">
                              <td colspan="6" class="text-center">
                                ไม่พบข้อมูลพิกัด
                              </td>
                            </tr>
                            <tr v-for="(item, index) in locations" :key="index">
                              <th scope="row">{{ index + 1 }}</th>
                              <td class="text-end">{{ item.from_latitude }}</td>
                              <td class="text-end">
                                {{ item.from_longitude }}
                              </td>
                              <td class="text-end">{{ item.to_latitude }}</td>
                              <td class="text-end">{{ item.to_longitude }}</td>
                              <td class="text-end">{{ item.distance }}</td>
                            </tr>
                            <tr>
                              <th colspan="5" class="text-end">
                                ระยะทางทั้งหมด
                              </th>

                              <td class="text-end">{{ distanceSum }}</td>
                            </tr>
                            <tr>
                              <th colspan="5" class="text-end">
                                ค่าน้ำมันไปกลับ (1 กม : 3 บ)
                              </th>
                              <td class="text-end">{{ fuelPrices }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="row mt-3 justify-content-end">
                      <div class="col-md-3 col-sm-4">
                        <label for=""></label><br />
                        <b-button
                          :disabled="
                            rows.status === 'SUBMIT' ||
                            rows.status === 'NAPV' ||
                            rows.status === 'BAPV' ||
                            rows.status === 'APV'
                          "
                          class="btn float-end"
                          variant="primary"
                          @click="RouteSubmit()"
                        >
                          <i class="uil-location-pin-alt me-1"></i>
                          บันทึกพิกัดปลายทาง
                        </b-button>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12">
                        <div id="map" style="height: 500px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div id="map" style="height: 500px"></div>
        </div>
      </div>
    </b-overlay>
    <b-modal
      ref="modal"
      id="modal"
      title="เพิ่มรถ"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <form class="needs-validation" @submit.prevent="companyVehicleForm">
          <div class="row">
            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">เลขตัวถัง :</label>
                <input
                  v-model="companyVehicle.vin"
                  type="text"
                  class="form-control"
                  placeholder="VIN"
                  maxlength="20"
                  :class="{
                    'is-invalid':
                      submitformVehicle && $v.companyVehicle.vin.$error,
                  }"
                />
                <div
                  v-if="submitformVehicle && $v.companyVehicle.vin.$error"
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.companyVehicle.vin.required">{{
                    errormessage
                  }}</span>
                </div>
              </div>
            </div>

            <div class="col-md-2">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">อักษร</label>
                <input
                  v-model="companyVehicle.platePrefixNumber"
                  type="text"
                  class="form-control"
                  placeholder="กก"
                  maxlength="4"
                  :class="{
                    'is-invalid':
                      submitformVehicle &&
                      $v.companyVehicle.platePrefixNumber.$error,
                  }"
                />
                <div
                  v-if="
                    submitformVehicle &&
                    $v.companyVehicle.platePrefixNumber.$error
                  "
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.companyVehicle.platePrefixNumber.required">{{
                    errormessage
                  }}</span>
                </div>
              </div>
            </div>

            <div class="col-md-2">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">เลข</label>
                <input
                  v-model="companyVehicle.plateNumber"
                  type="text"
                  class="form-control"
                  placeholder="0000"
                  maxlength="4"
                  :class="{
                    'is-invalid':
                      submitformVehicle && $v.companyVehicle.plateNumber.$error,
                  }"
                />
                <div
                  v-if="
                    submitformVehicle && $v.companyVehicle.plateNumber.$error
                  "
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.companyVehicle.plateNumber.required">{{
                    errormessage
                  }}</span>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">จังหวัด</label>
                <multiselect
                  v-model="companyVehicle.plateProvinceId"
                  label="nameTh"
                  :options="rowsProvince"
                  :show-labels="false"
                  open-direction="bottom"
                  placeholder=""
                >
                  <span slot="noResult">ไม่พบข้อมูล</span>
                </multiselect>
                <div
                  v-if="
                    submitformVehicle &&
                    $v.companyVehicle.plateProvinceId.$error
                  "
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.companyVehicle.plateProvinceId.required">{{
                    errormessage
                  }}</span>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">ป้ายทะเบียน:</label>
                <input
                  v-model="fullLicense"
                  type="text"
                  class="form-control"
                  placeholder=""
                  maxlength="20"
                  disabled
                  :class="{
                    'is-invalid':
                      submitformVehicle && $v.companyVehicle.plateNumber.$error,
                  }"
                />
                <div
                  v-if="
                    submitformVehicle && $v.companyVehicle.plateNumber.$error
                  "
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.companyVehicle.plateNumber.required">{{
                    errormessage
                  }}</span>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3 position-relative">
                สาขา :
                <multiselect
                  v-model="companyVehicle.branchId"
                  label="nameTh"
                  type="search"
                  :options="localDataBranch"
                  @input="getbranchUser"
                  open-direction="bottom"
                  placeholder="สาขา"
                >
                </multiselect>
                <!-- :class="{
                    'is-invalid':
                      submitformVehicle && $v.companyVehicle.branchId.$error,
                  }"
                <div
                  v-if="submitformVehicle && $v.companyVehicle.branchId.$error"
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.companyVehicle.branchId.required">{{
                    errormessage
                  }}</span>
                </div> -->
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3 position-relative">
                เจ้าของ :
                <multiselect
                  v-model="companyVehicle.userId"
                  type="search"
                  :options="rowsUser"
                  :custom-label="customLabel"
                  open-direction="bottom"
                  placeholder="เจ้าของ"
                >
                </multiselect>
                <!-- :class="{
                    'is-invalid':
                      submitformVehicle && $v.companyVehicle.userId.$error,
                  }"
                <div
                  v-if="submitformVehicle && $v.companyVehicle.userId.$error"
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.companyVehicle.userId.required">{{
                    errormessage
                  }}</span>
                </div> -->
              </div>
            </div>

            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label for="validation">ยี่ห้อ:</label>
                <multiselect
                  v-model="companyVehicle.brandId"
                  label="nameEn"
                  :options="masterBrand"
                  :show-labels="false"
                  open-direction="bottom"
                  placeholder=""
                >
                  <span slot="noResult">ไม่พบข้อมูล</span>
                </multiselect>
                <!-- :class="{
                    'is-invalid':
                      submitformVehicle && $v.companyVehicle.brandId.$error,
                  }"
                <div
                  v-if="submitformVehicle && $v.companyVehicle.brandId.$error"
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.companyVehicle.brandId.required">{{
                    errormessage
                  }}</span>
                </div> -->
              </div>
            </div>

            <div class="col-md-4">
              <div class="mb-3 position-relative">
                <label for="validation">ประเภท :</label>
                <multiselect
                  v-model="companyVehicle.ownerType"
                  label="nameTh"
                  :options="ownerTypeOptions"
                  :show-labels="false"
                  open-direction="bottom"
                  placeholder=""
                >
                  <span slot="noResult">ไม่พบข้อมูล</span>
                </multiselect>
                <!-- :class="{
                    'is-invalid':
                      submitformVehicle && $v.companyVehicle.ownerType.$error,
                  }"
                <div
                  v-if="submitformVehicle && $v.companyVehicle.ownerType.$error"
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.companyVehicle.ownerType.required">{{
                    errormessage
                  }}</span>
                </div> -->
              </div>
            </div>
          </div>
          <hr />

          <div class="row">
            <div class="text-end">
              <button type="submit" class="btn btn-success">บันทึก</button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
  </Layout>
</template>
